<template>
    <div class="study-list-view-container">
        <div class="title-container">
            <div class="back-icon" @click="closeView()"><i class="el-icon-back"></i><span class="view-name">{{patientName}}</span></div>
        </div>
        <div class="list-container">
            <div class="study-list-item" v-for="study in studyList" :key="study.risStudyId" @click="clickStudy(study.risStudyId)">
                <div class="study-info">
                    <div class="study-hospital">{{study.studyTime}} {{study.hospitalName}}</div>
                    <div><span class="study-item-title">检查项目：</span><span class="study-item">{{study.deviceType}} {{study.pacsStudyItem}} </span><span class="image-count">{{study.imageCount}}图</span></div>
                    <div class="diagnose-title">报告诊断：</div>
                    <div class="report-diagnose">{{study.reportDiagnose}}</div>
                </div>
                <div class="icon-box">
                    <div class="arrow-icon"><i class="el-icon-arrow-right"></i></div>
                </div>
            </div>
        </div>
        <div class="single-study-view" v-if="studyViewVisible">
            <!-- <SingleStudyView :risStudyId="selectedStudyId" v-on:closeView="closeStudyView" /> -->
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
// import {UserBrowseStudy} from '../../models/UserBrowseStudy'
import {MessageProxy} from '../../utils/MessageProxy'
import { store } from '../../utils/store'

export default {
    name: 'StudyListView',
    components: {
        // SingleStudyView
    },
    // props: {
    //     patientName: String
    // },
    data() {
        return {
            studyList: [],
            selectedStudyId: "",
            studyViewVisible: false,
            patientName: ""
        }
    },
    created() {
        // console.log(this.$route.params)
        let queryType = "";
        let queryCondition = {};
        if (this.$route.query.patientName != undefined || this.$route.query.queryKey != undefined) {
            this.patientName = this.$route.query.patientName;
            queryType = "searchStudy";
            queryCondition = {
                userId: store.userId,
                patientName: this.$route.query.patientName,
                queryKey: this.$route.query.queryKey,
                hospitalId: this.$route.query.hospitalId
            }
        } else if (this.$route.query.concernedPersonName != undefined || this.$route.query.concernedPersonNickname != undefined) {
            if (this.$route.query.concernedPersonNickname != undefined && this.$route.query.concernedPersonNickname != "") {
                this.patientName = this.$route.query.concernedPersonNickname + " (" + this.$route.query.concernedPersonName + ")";
            } else {
                this.patientName = this.$route.query.concernedPersonName;
            }
            queryType = "concernedPerson";
            queryCondition = {
                userId: store.userId,
                concernedPersonName: this.$route.query.concernedPersonName
            }
        }
        console.log(this.$route.params)
        console.log(this.$route.query)
        console.log('queryType', queryType)
        console.log('queryCondition', queryCondition)

        MessageProxy.getStudyList(queryType, queryCondition).then((result)=>{
            if (true == result.hasError) {
                this.$message.error(result.errorText);
                this.$router.go(-1);
                return;
            }
            if (result.data != null && result.data.code != '200') {
                this.$message.error(result.data.message);
                this.$router.go(-1);
                return;
            }
            this.studyList = result.data.data;
        })

        /*
        let study1 = new UserBrowseStudy();
        study1.risStudyId = "20200603_D9511E73F58545EABA73C41F845E43C1";
        study1.patientName = "王强";
        study1.nickname = "爸爸";
        study1.studyTime = "2020-8-28";
        study1.deviceType = "CT";
        study1.pacsStudyItem = "头颅平扫";
        study1.imageCount = 350;
        study1.reportDiagnose = "头颅CT平扫未见异常。";
        study1.hospitalName = "沈阳第一医院";
        study1.browseTime = "2020-9-1";
        this.studyList.push(study1);

        let study2 = new UserBrowseStudy();
        study2.risStudyId = "20200427_2D1BFEE4621447A6958604E7F29C37D5";
        study2.patientName = "李丽";
        study2.nickname = "小姨";
        study2.studyTime = "2020-3-15";
        study2.deviceType = "CT";
        study2.pacsStudyItem = "腰椎平扫";
        study2.imageCount = 620;
        study2.reportDiagnose = "L3/4、L4/5、L5/S1间盘CT平扫未见明确异常。";
        study2.hospitalName = "医大第一医院";
        study2.browseTime = "2020-3-16";
        this.studyList.push(study2);

        let study3 = new UserBrowseStudy();
        study3.risStudyId = "20200427_5A92D48A190F4800BE628DF248FC84DE";
        study3.patientName = "刘宏";
        study3.nickname = "朋友";
        study3.studyTime = "2019-5-20";
        study3.deviceType = "CT";
        study3.pacsStudyItem = "颈椎平扫";
        study3.imageCount = 280;
        study3.reportDiagnose = "颈椎CT扫描未见异常。";
        study3.hospitalName = "医大第三医院";
        study3.browseTime = "2019-5-21";
        this.studyList.push(study3);
        */
    },
    methods: {
        closeView() {
            this.$emit("closeView");
            this.$router.go(-1);
        },
        clickStudy(studyId) {
            // this.$emit("openStudy", studyId);
            this.selectedStudyId = studyId;
            // this.studyViewVisible = true;
            // this.$router.push('/studyinfo/' + studyId);
            this.$router.push({ name: 'studyinfo', params: { studyID: studyId }});
        },
        closeStudyView() {
            this.studyViewVisible = false;
        }
    }
}
</script>

<style scoped>
    .study-list-view-container {
        position: relative;
        height: 100%;
        overflow-y: auto;
    }
    .title-container {
        height: 12vmin;

        display: flex;
        align-items: stretch;
    }
    .title-container .back-icon {
        font-size: 6vmin;
        padding: 0 3vmin;

        display: flex;
        align-items: center;
    }
    .title-container .view-name {
        font-size: 4vmin;
        margin-left: 3vmin;
    }
    .title-container .back-icon:active {
        background-color: #eaeaea;
    }

    .list-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 12vmin;
        bottom: 0;

        font-size: 4vmin;
        text-align: left;
        overflow-y: auto;
    }
    .study-list-item {
        position: relative;
        background-color: #fff;
        color: #222;
        /* border-top: 1px solid #c0c0c0;
        border-bottom: 1px solid #c0c0c0; */
        margin: 0 0 2vmin 0;
        padding: 3vmin;
        text-align: left;
        font-size: 4vmin;

        display: flex;
        align-items: stretch;
    }
    .study-list-item:active {
        background-color: #eaeaea;
    }

    .study-list-item .study-info {
        flex-grow: 1;
        font-size: 4vmin;
    }
    .study-list-item .icon-box {
        font-size: 8vmin;
        color: #ccc;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .study-list-item .study-hospital {
        font-size: 4.5vmin;
        color: #222;
        margin-bottom: 1.5vmin;
    }
    .study-list-item .report-diagnose {
        color: #555;
    }
    .study-list-item .study-item-title,
    .study-list-item .diagnose-title {
        font-size: 4vmin;
        color: #888;
    }
    .study-list-item .image-count {
        background-color: #f6f6f6;
        padding: 1vmin 3vmin;
        border-radius: 5vmin;
    }

    .single-study-view {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        z-index: 3;
    }
</style>
